.parkings {
    width: 50%;
    max-width: 750px;
    display: flex;
    margin: auto auto;
}

@media only screen and (min-width: 800px) {
    :root {
        --height-of-animation: 50%;
        --width-of-parking: calc((50vw/3*2) - 4px);
        --width-of-cars: calc(((50vw/3) - 8px)*0.95 );
        --width-of-third-step: calc(var(--width-of-parking) - var(--width-of-cars) * 2)
    }
}

@media only screen and (min-width: 1400px) {
    :root {
        --height-of-animation: 50%;
        --width-of-parking: calc((750px/3*2) - 4px);
        --width-of-cars: calc(((750px/3) - 8px)*0.95 );
        --width-of-third-step: calc(var(--width-of-parking) - var(--width-of-cars) * 2)
    }
}

@media only screen and (max-width: 800px) {
    :root {
        --height-of-animation: 40%;
        --width-of-parking: calc((150vw/3*2) - 4px);
        --width-of-cars: calc(((150vw/3) - 8px)*0.95 );
        --width-of-third-step: calc(var(--width-of-parking) - var(--width-of-cars) * 2)
    }

    .animation {
        transform: rotate(-180deg);
        width: 150vw;
        margin-left: -25vw;
    }
    .parkings {
        width: 150vw;
    }
}

.animation {
    height: var(--height-of-animation);
    background-color: lightgrey;
}

.sidewalk {
    width: 100%;
    background:linear-gradient(to bottom, grey, darkgrey);
    height: 15px;
}

#car {
    width: var(--width-of-cars);
}

.car_one {
    margin-top: 10px;
    width: 95%;
}

.car_two {
    margin-top: 10px;
    width: 95%;
}

.parking {
    border-left: 4px solid;
    border-color: white;
    width: 33%;
}

.last_parking {
    border-left: 4px solid;
    border-right: 4px solid;
    border-color: white;
    width: 33%;
}

.car_with_step_0 {
    display: none;
}

.car_with_step_1 {
    margin-right: var(--width-of-parking);
    border-right: 2px solid red;
    padding-top: 105px;
    margin-top: -90px;
    animation-iteration-count: 1;
    animation-name: run_0;
    animation-duration: 2s;
}

.car_with_step_2 {
    margin-top: 5px;
    margin-right: var(--width-of-parking);
    border-top: 10px dotted yellow;
}

.car_with_step_3 {
    margin-top: -55px;
    margin-right: var(--width-of-third-step);
    transform: rotate(-40deg);
    border-bottom: 2px solid red;
    padding-right: 250px;
    animation-iteration-count: 1;
    animation-name: run_2;
    animation-duration: 2s;
}

.car_with_step_4 {
    margin-top: -70px;
    margin-left: -20px;
    transform: rotate(-40deg);
    padding-right: 50px;
    animation-iteration-count: 1;
    animation-name: run_3;
    animation-duration: 2s;
}

.car_with_step_5 {
    margin-top: -110px;
    margin-left: 25px;
    transform: rotate(-10deg);
    padding-bottom: 20px;
    animation-iteration-count: 1;
    animation-name: run_4;
    animation-duration: 2s;
}

.car_with_step_6 {
    margin-top: -110px;
    padding-bottom: 20px;
    animation-iteration-count: 1;
    animation-name: run_5;
    animation-duration: 2s;
}

@keyframes run_0 {
    from {
        margin-left: 100vw;
        border-right: none;
    }
    99%{
        border-right: none;
    }
    to {
        margin-right: var(--width-of-parking);
        border-right: 2px solid red;
        padding-top: 105px;
        margin-top: -90px;
    }
}

@keyframes run_2 {
    from {
        margin-top: 15px;
        margin-right: calc(var(--width-of-parking) - 250px);
        padding-right: 250px;
        transform: rotate(0);
        border-bottom: 0 solid red;
    }
    99% {
        border-bottom: 0 solid red;
    }
    to {
        margin-top: -55px;
        margin-right: var(--width-of-third-step);
        transform: rotate(-40deg);
        border-bottom: 2px solid red;
        padding-right: 250px;
    }
}

@keyframes run_3 {
    from {
        margin-left: 0;
        margin-top: -55px;
        margin-right: var(--width-of-third-step);
        transform: rotate(-40deg);
        border-bottom: 2px solid transparent;
        padding-right: 250px;
    }
    to {
        border-bottom: 2px solid transparent;
        margin-top: -70px;
        margin-left: -20px;
        transform: rotate(-40deg);
        padding-right: 50px;
    }
}

@keyframes run_4 {
    from {
        margin-top: -70px;
        margin-left: -20px;
        transform: rotate(-40deg);
        padding-right: 50px;
        padding-bottom: 0;
    }
    to {
        margin-top: -110px;
        margin-left: 25px;
        transform: rotate(-10deg);
        padding-bottom: 20px;
    }
}

@keyframes run_5 {
    from {
        margin-top: -110px;
        margin-left: 25px;
        transform: rotate(-10deg);
        padding-bottom: 20px;
    }
    to {
        margin-top: -110px;
        padding-bottom: 20px;
    }
}
