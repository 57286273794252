.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.fill {
  height: 100%;
  width: 100%;
}

html,body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
  height: 100%;
}

@media only screen and (min-width: 800px) {
  .footer {
    position: fixed;
    right: 20px;
    bottom: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    position: fixed;
    right: 20px;
    top: 20px;
  }
}