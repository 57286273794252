.step {
    max-width: 600px;
    margin: auto;
    font-size: 18px;
    vertical-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 1400px) {
    .step {
        height: 50%;
    }
}

@media only screen and (min-width: 800px) {
    .step {
        height: 50%;
    }
}


@media only screen and (max-width: 800px) {
    .step {
        width: 90%;
        height: 60%;
    }
}

.header_app {
    margin-bottom: 60px;
    width: 100%;
    font-size: 60px
}

.step-header {
    margin-bottom: 15px;
}

.text-box {
    height: 50px;
}
.buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 10px;
}

/* CSS */
.buttons button {
    margin-top: 20px;
    align-items: center;
    appearance: none;
    background-color: white;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    will-change: transform, opacity;
    z-index: 0;
}

.button-17:disabled:hover {
    cursor: not-allowed;
}

.button-17:disabled {
    background-color: #EEEEEE;
}

.button-17:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
}

.button-17:focus {
    outline: none;
    border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
    background: #F6F9FE;
    color: #174ea6;
}

.button-17:disabled:hover {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}